import { CapabilityKey } from "~/types/enums/CapabilityKeys.enum";
import { CapabilityDataType } from "~/types/enums/CapabilityDataType.enum";

import {
  CapabilityPackage,
  CapabilityPackageConstructorParams,
} from "../capabilities/capability-package.model";
import { ApplicationCapabilityDefinition } from "./application-capability-definition.model";
import { CapabilitiesService } from "./capabilities.service";
import { Capability } from "./capability.model";
import { UserPackageVersion } from "./user-package-version.model";
import { SubscriptionInfo } from "./subscription-info.model";
import { or } from "firebase/firestore";

export type UserPackages = UserPackage[];

interface UserPackageConstructorParams
  extends CapabilityPackageConstructorParams {
  userId: string;
  quantity: number;
  packageId: string;
  id: string;
  organizationId?: string;
  subscriptionInfo?: SubscriptionInfo;
}

export class UserPackage extends CapabilityPackage {
  userId: string;
  packageId: string;
  quantity: number;
  organizationId?: string;
  subscriptionInfo?: SubscriptionInfo;

  constructor(params: UserPackageConstructorParams) {
    super(params);

    this.packageId = params.packageId;
    this.userId = params.userId;
    this.quantity = params.quantity;
    this.organizationId = params.organizationId;
    this.subscriptionInfo = params.subscriptionInfo;
  }

  static get combinedIdentifier() {
    return "combined";
  }

  get databaseConfig() {
    var identifier = this.useCategoryAsId == true ? this.category : this.id;

    return {
      path: `users/${this.userId}/capabilities/${this.version}/packages/${identifier}`,
      collection: `users/${this.userId}/capabilities/${this.version}/packages`,
    };
  }

  static fromMap(map: any): UserPackage {
    if (map.subscriptionInfo) {
      map.subscriptionInfo = SubscriptionInfo.fromMap(map.subscriptionInfo);
    }

    return new UserPackage(map);
  }

  static fromPackage(
    userId: string,
    capabilityPackage: CapabilityPackage,
    quantity: number = 1,
    organizationId?: string
  ) {
    return new UserPackage({
      id: capabilityPackage.id!,
      packageId: capabilityPackage.id!,
      name: capabilityPackage.name,
      category: capabilityPackage.category,
      version: capabilityPackage.version,
      lastUpdatedTimestamp: capabilityPackage.lastUpdatedTimestamp,
      capabilities: capabilityPackage.capabilities,
      allowQuantity: capabilityPackage.allowQuantity,
      type: capabilityPackage.type,
      displayOrder: capabilityPackage.displayOrder,
      userId: userId,
      quantity: quantity,
      organizationId: organizationId,
      useCategoryAsId: capabilityPackage.useCategoryAsId,
      consumableProductId: capabilityPackage.consumableProductId,
      nonConsumableProducts: capabilityPackage.nonConsumableProducts,
      description: capabilityPackage.description,
    });
  }

  toMap(): ModelDatabaseData {
    return {
      id: this.id,
      packageId: this.packageId,
      name: this.name,
      category: this.category,
      version: this.version,
      lastUpdatedTimestamp: this.lastUpdatedTimestamp,
      capabilities: this.capabilities,
      allowQuantity: this.allowQuantity,
      type: this.type,
      displayOrder: this.displayOrder,
      userId: this.userId,
      quantity: this.quantity,
      organizationId: this.organizationId,
      useCategoryAsId: this.useCategoryAsId,
      consumableProductId: this.consumableProductId,
      nonConsumableProducts: this.nonConsumableProducts,
      subscriptionInfo: this.subscriptionInfo?.toMap(),
    };
  }

  static async applyPackageToUser({
    userId,
    capabilityPackage,
    quantity = 1,
    organizationId,
    subscriptionInfo,
  }: {
    userId: string;
    capabilityPackage: CapabilityPackage;
    quantity: number;
    organizationId?: string;
    subscriptionInfo?: SubscriptionInfo;
  }) {
    let userPackage = new UserPackage({
      id: capabilityPackage.id!,
      userId: userId,
      version: capabilityPackage.version,
      name: capabilityPackage.name,
      category: capabilityPackage.category,
      type: capabilityPackage.type,
      organizationId: organizationId,
      capabilities: capabilityPackage.capabilities,
      lastUpdatedTimestamp: new Date().getTime(),
      quantity: quantity,
      displayOrder: capabilityPackage.displayOrder,
      packageId: capabilityPackage.id!,
      allowQuantity: capabilityPackage.allowQuantity,
      useCategoryAsId: capabilityPackage.useCategoryAsId,
      subscriptionInfo: subscriptionInfo,
      consumableProductId: capabilityPackage.consumableProductId,
      nonConsumableProducts: capabilityPackage.nonConsumableProducts,
      description: capabilityPackage.description,
    });

    const userPackageVersion = new UserPackageVersion({
      userId: userId,
      version: capabilityPackage.version,
      organizationId: organizationId,
    });

    await userPackageVersion.save();

    await userPackage.save(true);
  }

  static async removePackageFromUser(
    userId: string,
    capabilityPackage: CapabilityPackage
  ) {
    const userPackage = new UserPackage({
      id: capabilityPackage.id!,
      userId: userId,
      version: capabilityPackage.version,
      packageId: capabilityPackage.id!,
      name: capabilityPackage.name,
      category: capabilityPackage.category,
      type: capabilityPackage.type,
      capabilities: capabilityPackage.capabilities,
      lastUpdatedTimestamp: new Date().getTime(),
      quantity: 0,
      displayOrder: capabilityPackage.displayOrder,
      allowQuantity: capabilityPackage.allowQuantity,
      useCategoryAsId: capabilityPackage.useCategoryAsId,
      consumableProductId: capabilityPackage.consumableProductId,
      nonConsumableProducts: capabilityPackage.nonConsumableProducts,
      description: capabilityPackage.description,
    });

    await userPackage.delete();
  }

  getCapabilityValue(key: string) {
    return this.capabilities[key];
  }

  getCapabilityValueWithDefinition(
    key: CapabilityKey,
    capabilityDefinition: Capability
  ) {
    const value = this.getCapabilityValue(key);

    if (value === undefined) return undefined;

    if (capabilityDefinition.dataType === CapabilityDataType.boolean) {
      return value;
    }

    return capabilityDefinition.inheritPackageQuantity
      ? (value as number) * this.quantity
      : (value as number);
  }
}
