import { DBFactory } from "classes/database/db_factory";
import { BaseModel } from "../base.model";
import { ApplicationCapabilityCategorySelectionMethod } from "~/types/enums/ApplicationCapabilityCategorySelectionMethod.enum";

type ApplicationCapabilityCategoryConstructorParams = {
  id: string;
  name: string;
  selectionMethod: ApplicationCapabilityCategorySelectionMethod;
  displayOrder: number;
  isRequired?: boolean;
};

export type ApplicationCapabilityCategories = ApplicationCapabilityCategory[];

export class ApplicationCapabilityCategory extends BaseModel {
  id: string;
  name: string;
  selectionMethod: ApplicationCapabilityCategorySelectionMethod;
  displayOrder: number;
  isRequired: boolean;

  constructor(params: ApplicationCapabilityCategoryConstructorParams) {
    super();
    this.id = params.id;
    this.name = params.name;
    this.selectionMethod = params.selectionMethod;
    this.displayOrder = params.displayOrder;
    this.isRequired = params.isRequired ?? false;
  }

  static fromMap(map: any): ApplicationCapabilityCategory {
    return new ApplicationCapabilityCategory(map);
  }

  get databaseConfig() {
    return {
      path: `applicationCapabilityCategories/${this.id}`,
      collection: "applicationCapabilityCategories",
    };
  }

  async save() {
    const db = DBFactory.createDatabase();
    await db.save(this.toMap(), this.databaseConfig);
  }
}
