import { DBFactory } from "classes/database/db_factory";
import { BaseModel } from "../base.model";
import { CapabilityKey } from "~/types/enums/CapabilityKeys.enum";

type UserConsumablesParams = {
  userId: string;
  consumedCapabilities: { [K in CapabilityKey]?: number };
};

export class UserConsumables extends BaseModel {
  userId: string;
  consumedCapabilities: { [K in CapabilityKey]?: number };

  static id = "default";

  constructor(params: UserConsumablesParams) {
    super();
    this.userId = params.userId;
    this.consumedCapabilities = params.consumedCapabilities;
  }

  static fromMap(map: any): UserConsumables {
    return new UserConsumables(map);
  }

  get databaseConfig() {
    return {
      path: `/users/${this.userId}/consumables/${UserConsumables.id}`,
      collection: `/users/${this.userId}/consumables`,
    };
  }

  getConsumedCapabilityValue(key: CapabilityKey): number {
    if (this.consumedCapabilities == undefined) return 0;

    return this.consumedCapabilities[key] ?? 0;
  }

  async save(merge: boolean = true) {
    const db = DBFactory.createDatabase();
    await db.save(this.toMap(), this.databaseConfig, merge);
  }
}
