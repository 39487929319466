import { DBFactory } from "classes/database/db_factory";
import { Observable, map } from "rxjs";
import { Organization, Organizations } from "./organization.model";
import {
  OrganizationPackage,
  OrganizationPackages,
} from "./organization-package.model";
import { CapabilityPackage } from "../capabilities/capability-package.model";
import { CapabilitiesService } from "../capabilities/capabilities.service";
import { UserPackage, UserPackages } from "../capabilities/user-package.model";
import { UserCapabilities } from "../capabilities/user-capabilities.model";
import { SubscriptionInfo } from "../capabilities/subscription-info.model";
import { s } from "vitest/dist/reporters-5f784f42.js";
import { OrganizationSummary } from "./organization-summary.model";
import { LMSOrganizationConfig } from "./lms-organization-config.model";

export class OrganizationsService {
  static streamOrganizations(): Observable<Organizations> {
    const db = DBFactory.createDatabase();
    return db
      .streamList({
        path: "/organizations",
        collection: "/organizations",
      })
      .pipe(
        map((data: any) => {
          return data.map((org: any) => {
            return Organization.fromMap(org);
          });
        })
      );
  }

  static async get(id: string): Promise<Organization> {
    try {
      const db = DBFactory.createDatabase();
      const data = await db.get({
        path: `/organizations/${id}`,
        collection: `/organizations`,
      });

      if (!data) {
        throw new Error("Organization not found");
      }

      return Organization.fromMap(data);
    } catch (error) {
      throw new Error("Organization not found");
    }
  }

  static async getOrganizationLMSConfig(
    organizationId: string
  ): Promise<LMSOrganizationConfig | undefined> {
    try {
      const db = DBFactory.createDatabase();
      const data = await db.get({
        path: `/lmsOrganizationConfigs/${organizationId}`,
        collection: `/lmsOrganizationConfigs`,
      });

      return LMSOrganizationConfig.fromMap(data);
    } catch (error) {
      return undefined;
    }
  }

  static streamOrganizationLMSConfig(
    organizationId: string
  ): Observable<LMSOrganizationConfig | undefined> {
    const db = DBFactory.createDatabase();
    return db
      .streamRecord({
        path: `/lmsOrganizationConfigs/${organizationId}`,
        collection: `/lmsOrganizationConfigs`,
      })
      .pipe(
        map((data) => {
          if (!data) return undefined;

          return LMSOrganizationConfig.fromMap(data);
        })
      );
  }

  static streamOrganizationPurchasedPackages(
    organizationId: string
  ): Observable<OrganizationPackages> {
    const db = DBFactory.createDatabase();
    return db
      .streamList({
        path: `/organizations/${organizationId}/purchasedPackages`,
        collection: `/organizations/${organizationId}/purchasedPackages`,
      })
      .pipe(
        map((data: any) => {
          return data.map((orgPackage: any) => {
            return OrganizationPackage.fromMap(orgPackage);
          });
        })
      );
  }

  static async getOrganizationPurchasedPackages(
    organizationId: string
  ): Promise<OrganizationPackages> {
    let queryConfig: ModelQueryConfig = [];

    const db = DBFactory.createDatabase();
    const data = await db.list(
      {
        path: `/organizations/${organizationId}/purchasedPackages`,
        collection: `/organizations/${organizationId}/purchasedPackages`,
      },
      queryConfig
    );

    return data.map((orgPackage: any) => {
      return OrganizationPackage.fromMap(orgPackage);
    });
  }

  static async getOrganizationPurchasedPackage(
    organizationId: string,
    packageId: string
  ): Promise<OrganizationPackage> {
    const db = DBFactory.createDatabase();
    const data = await db.get({
      path: `/organizations/${organizationId}/purchasedPackages/${packageId}`,
      collection: `/organizations/${organizationId}/purchasedPackages`,
    });

    return OrganizationPackage.fromMap(data);
  }

  static streamOrganizationUsers(
    organizationId: string
  ): Observable<AppUser[]> {
    const db = DBFactory.createDatabase();

    return db
      .streamList(
        {
          path: `/users`,
          collection: `users`,
        },
        [
          {
            type: "where",
            field: "organizationId",
            operator: "==",
            value: organizationId,
          },
        ]
      )
      .pipe(
        map((data: any) => {
          return data.map((user: any) => {
            return user as AppUser;
          });
        })
      );
  }

  static async getOrganizationUsers(organizationId: string) {
    const db = DBFactory.createDatabase();
    const data = await db.list(
      {
        path: `/users`,
        collection: `users`,
      },
      [
        {
          type: "where",
          field: "organizationId",
          operator: "==",
          value: organizationId,
        },
      ]
    );

    return data.map((user: any) => {
      return user as AppUser;
    });
  }

  static streamOrganizationUsedPackages(
    organizationId: string
  ): Observable<UserPackages> {
    const db = DBFactory.createDatabase();
    return db
      .streamList(
        {
          collectionGroup: "packages",
          path: ``,
          collection: "",
        },
        [
          {
            type: "where",
            field: "organizationId",
            operator: "==",
            value: organizationId,
          },
        ]
      )
      .pipe(
        map((data: any) => {
          return data.map((userCap: any) => {
            return UserPackage.fromMap(userCap);
          });
        })
      );
  }

  static async getOrganizationUsedPackages(
    organizationId: string,
    packageId?: string
  ) {
    let queryConfig: ModelQueryConfig = [
      {
        type: "where",
        field: "organizationId",
        operator: "==",
        value: organizationId,
      },
    ];

    if (packageId) {
      queryConfig.push({
        type: "where",
        field: "packageId",
        operator: "==",
        value: packageId,
      });
    }

    const db = DBFactory.createDatabase();

    try {
      const data = await db.list(
        {
          collectionGroup: "packages",
          path: ``,
          collection: "",
        },
        queryConfig
      );
    } catch (error) {}

    const data = await db.list(
      {
        collectionGroup: "packages",
        path: ``,
        collection: "",
      },
      queryConfig
    );

    return data.map((userCap: any) => {
      return UserPackage.fromMap(userCap);
    });
  }

  static async linkUserToOrganization(organizationId: string, email: string) {
    const response = await useFetch(
      `/api/organizations/${organizationId}/users/linkUser`,
      {
        method: "POST",
        headers: await useApiHeaders(),
        body: {
          email: email,
        },
      }
    );

    if (response.error.value) {
      useBaseAlert("Oops!", "There was a problem linking this user.");
    }
  }

  static async unlinkUserFromOrganization(
    userId: string,
    organizationId: string,
    userPackages: UserPackages
  ) {
    await $fetch(`/api/organizations/${organizationId}/users/unlinkUser`, {
      method: "POST",
      headers: await useApiHeaders(),
      body: {
        userId,
      },
    });
  }

  static async applyPackageToOrganization({
    capabilityPackage,
    organizationId,
    quantity = 1,
    subscriptionInfo,
  }: {
    capabilityPackage: CapabilityPackage;
    organizationId: string;
    quantity: number;
    subscriptionInfo?: SubscriptionInfo;
  }) {
    const organizationPackage = OrganizationPackage.fromPackage({
      capabilityPackage,
      organizationId,
      quantity,
      subscriptionInfo,
    });

    if (quantity > 0) {
      await organizationPackage.save();
    } else {
      await organizationPackage.delete();
    }
  }

  static async doesUserBelongToOrganization(
    userId: string,
    organizationId: string
  ) {
    try {
      const organizationUsers = await OrganizationsService.getOrganizationUsers(
        organizationId
      );

      const user = organizationUsers.find((user) => user.id === userId);
      return user ? true : false;
    } catch (error) {
      return false;
    }
  }

  static async getNumAvailableLicensesForPackage(
    organizationId: string,
    packageId: string
  ): Promise<number> {
    try {
      const organizationPackage =
        await OrganizationsService.getOrganizationPurchasedPackage(
          organizationId,
          packageId
        );

      // Reduce organizationPackages to a totla quantity
      const totalPurchasedQuantity = organizationPackage.quantity;

      const usedPackages =
        await OrganizationsService.getOrganizationUsedPackages(
          organizationId,
          packageId
        );

      const usedQuantity = usedPackages.reduce(
        (acc, usedPackage) => acc + usedPackage.quantity,
        0
      );

      return totalPurchasedQuantity - usedQuantity;
    } catch (error) {
      return 0;
    }
  }

  static async getOrganizationUserCapabilities({
    organizationId,
    userId,
  }: {
    organizationId: string;
    userId: string;
  }) {
    const response = await $fetch(
      `/api/organizations/${organizationId}/users/${userId}/capabilities`,
      {
        headers: await useApiHeaders(),
      }
    );

    return UserCapabilities.fromMap(response);
  }

  static async applyPackageToUser(
    organizationId: string,
    userId: string,
    packageId: string,
    incrementQuantity: number
  ) {
    const doesUserBelong =
      await OrganizationsService.doesUserBelongToOrganization(
        userId,
        organizationId
      );

    if (doesUserBelong != true) {
      throw new Error("User does not belong to organization");
    }

    const numAvailableLicenses = await this.getNumAvailableLicensesForPackage(
      organizationId,
      packageId
    );

    if (numAvailableLicenses < incrementQuantity) {
      throw new Error("You do not have enough licenses available.");
    }

    const usedPackages = await OrganizationsService.getOrganizationUsedPackages(
      organizationId,
      packageId
    );
    const usedPackagesForUser = usedPackages.filter(
      (usedPackage) => usedPackage.userId === userId
    );

    let existingQuantity = 0;
    if (usedPackagesForUser.length > 0) {
      existingQuantity = usedPackagesForUser[0].quantity;
    }

    // Ensure we can't have a negative amount.
    const newLicenseQuantity = Math.max(
      existingQuantity + incrementQuantity,
      0
    );

    await CapabilitiesService.applyPackageToUser({
      packageId,
      userId,
      quantity: newLicenseQuantity,
      organizationId,
    });
  }

  static streamOrganizationSummary({
    organizationId,
  }: {
    organizationId: string;
  }): Observable<OrganizationSummary> {
    const db = DBFactory.createDatabase();
    return db
      .streamRecord({
        path: `/organizationSummaries/${organizationId}`,
        collection: `/organizationSummaries`,
      })
      .pipe(
        map((data: any) => {
          return OrganizationSummary.fromMap(data);
        })
      );
  }

  static async getOrganizationSummary({
    organizationId,
  }: {
    organizationId: string;
  }): Promise<OrganizationSummary> {
    const db = DBFactory.createDatabase();
    const data = await db.get({
      path: `/organizationSummaries/${organizationId}`,
      collection: `/organizationSummaries`,
    });

    return OrganizationSummary.fromMap(data);
  }

  static async saveOrganizationSummary({
    organizationId,
  }: {
    organizationId: string;
  }): Promise<void> {
    const organization = await OrganizationsService.get(organizationId);
    const organizationPackages =
      await OrganizationsService.getOrganizationPurchasedPackages(
        organizationId
      );
    const userPackages = await OrganizationsService.getOrganizationUsedPackages(
      organizationId
    );

    const packagesMap = {} as {
      [key: string]: {
        packageId: string;
        packageName: string;
        purchasedQuantity: number;
        usedQuantity: number;
        subscriptionExpiryTimestamp?: number;
      };
    };

    for (const organizationPackage of organizationPackages) {
      const usedPackages = userPackages.filter(
        (userPackage) => userPackage.packageId === organizationPackage.id
      );

      const usedQuantity = usedPackages.reduce(
        (acc, usedPackage) => acc + usedPackage.quantity,
        0
      );

      packagesMap[organizationPackage.id!] = {
        packageId: organizationPackage.id!,
        packageName: organizationPackage.name,
        purchasedQuantity: organizationPackage.quantity,
        usedQuantity,
        subscriptionExpiryTimestamp:
          organizationPackage.subscriptionInfo?.subscriptionExpiryTimestamp,
      };
    }

    const organizationSummary = new OrganizationSummary({
      organizationId,
      organizationName: organization.name,
      packagesMap: packagesMap,
    });

    try {
      await organizationSummary.save();
    } catch (error) {}
  }
}
