import { DBFactory } from "classes/database/db_factory";
import { UserCounts } from "./user-counts.model";
import { map, Observable, of, switchMap } from "rxjs";
import { SubscriptionRenewalPeriod } from "./subscription-renewal-period";
import { useCreateUserCountKey } from "~/composables/useCreateUserCountKey";

export class UserCountsService {
  static async getUserCounts(userId: string) {
    const db = DBFactory.createDatabase();
    const key = await UserCountsService.getsubscriptionRenewalDayOfMonth({
      userId,
    });

    try {
      const data = await db.get({
        path: `users/${userId}/counts/${key}`,
        collection: `users/${userId}/counts/${key}`,
      });

      const userCounts = UserCounts.fromMap(data);
      return userCounts;
    } catch (error) {
      return new UserCounts({
        id: key,
        submissions: [],
      });
    }
  }

  static streamUserCounts(userId: string) {
    const db = DBFactory.createDatabase();
    return UserCountsService.streamSubscriptionRenwalDayOfMonth({
      userId,
    }).pipe(
      switchMap((key) => {
        return db
          .streamRecord({
            path: `users/${userId}/counts/${key}`,
            collection: `users/${userId}/counts/${key}`,
          })
          .pipe(
            map((data) => {
              if (data == undefined) {
                return new UserCounts({
                  id: key,
                  submissions: [],
                });
              }
              return UserCounts.fromMap(data);
            })
          );
      })
    );
  }

  static async getsubscriptionRenewalDayOfMonth({
    userId,
  }: {
    userId: string;
  }): Promise<string> {
    const db = DBFactory.createDatabase();

    try {
      const userDocData = await db.get({
        collection: "/users",
        path: `/users/${userId}`,
      });

      const identifier = userDocData.organizationId ?? userId;

      const data = await db.get({
        collection: "/subscriptionRenewalPeriods",
        path: `/subscriptionRenewalPeriods/${identifier}`,
      });

      const renwalPeriod = SubscriptionRenewalPeriod.fromMap(data);
      return useCreateUserCountKey({
        subscriptionRenewalDayOfMonth:
          renwalPeriod.subscriptionRenewalDayOfMonth,
      });
    } catch (error) {
      return useCreateUserCountKey({});
    }
  }

  static streamSubscriptionRenwalDayOfMonth({
    userId,
  }: {
    userId: string;
  }): Observable<string> {
    const db = DBFactory.createDatabase();
    return db
      .streamRecord({
        collection: "/users",
        path: `/users/${userId}`,
      })
      .pipe(
        switchMap((userDocData) => {
          if (userDocData == undefined) {
            return of(undefined);
          }

          const identifier = userDocData.organizationId ?? userId;

          return db
            .streamRecord({
              collection: "/subscriptionRenewalPeriods",
              path: `/subscriptionRenewalPeriods/${identifier}`,
            })
            .pipe(
              map((data) => {
                if (data == undefined) {
                  return undefined;
                }
                const renwalPeriod = SubscriptionRenewalPeriod.fromMap(data);
                return renwalPeriod.subscriptionRenewalDayOfMonth;
              })
            );
        }),
        map((subscriptionRenewalDayOfMonth) => {
          return useCreateUserCountKey({
            subscriptionRenewalDayOfMonth,
          });
        })
      );
  }
}
