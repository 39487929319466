import { BaseModel } from "../base.model";
import { DBFactory } from "classes/database/db_factory";

type LMSOrganizationConfigContrusctorParams = {
  id: string;
  provider: "sakai" | "google" | "canvas";
};

export class LMSOrganizationConfig extends BaseModel {
  id: string;
  provider: "sakai" | "google" | "canvas";

  constructor(data: LMSOrganizationConfigContrusctorParams) {
    super(data);

    this.id = data.id;
    this.provider = data.provider;
  }

  get databaseConfig(): ModelDatabaseConfig {
    return {
      collection: "/lmsOrganizationConfigs",
      path: `/lmsOrganizationConfigs/${this.id}`,
    };
  }

  static fromMap(map: any): LMSOrganizationConfig {
    switch (map.provider) {
      case "sakai":
      default:
        return new SakaiLMSOrganizationConfig(map);
    }
  }

  async save() {
    const db = DBFactory.createDatabase();
    await db.save(this.toMap(), this.databaseConfig);
  }
}

type SakaiLMSOrganizationConfigContrusctorParams = Omit<
  LMSOrganizationConfigContrusctorParams,
  "provider"
> & {
  config: {
    endpoint: string;
    username: string;
    password: string;
    soapEndpoint: string;
  };
};

export class SakaiLMSOrganizationConfig extends LMSOrganizationConfig {
  config: {
    endpoint: string;
    username: string;
    password: string;
    soapEndpoint: string;
  };

  constructor(data: SakaiLMSOrganizationConfigContrusctorParams) {
    super({
      ...data,
      provider: "sakai",
    });

    this.config = data.config;
  }
}
