import { DBFactory } from "classes/database/db_factory";
import { BaseModel } from "../base.model";

type OrganizationSummaryConstructorParams = {
  organizationId: string;
  organizationName: string;
  packagesMap: {
    [key: string]: {
      packageId: string;
      packageName: string;
      purchasedQuantity: number;
      usedQuantity: number;
      subscriptionExpiryTimestamp?: number;
    };
  };
};

export class OrganizationSummary extends BaseModel {
  id: string;
  organizationId: string;
  organizationName: string;
  packagesMap: {
    [key: string]: {
      packageId: string;
      packageName: string;
      purchasedQuantity: number;
      usedQuantity: number;
      subscriptionExpiryTimestamp?: number;
    };
  };

  constructor(params: OrganizationSummaryConstructorParams) {
    super();

    this.id = params.organizationId;
    this.organizationId = params.organizationId;
    this.organizationName = params.organizationName;
    this.packagesMap = params.packagesMap;
  }

  static fromMap(map: any): OrganizationSummary {
    return new OrganizationSummary(map);
  }

  toMap(): any {
    return {
      id: this.organizationId,
      organizationId: this.organizationId,
      organizationName: this.organizationName,
      packagesMap: this.packagesMap,
    };
  }

  get databaseConfig() {
    return {
      path: `/organizationSummaries/${this.organizationId}`,
      collection: `/organizationSummaries`,
    };
  }

  isPackageActive(packageId: string): boolean {
    const packageInfo = this.packagesMap[packageId];

    if (!packageInfo) {
      return false;
    }

    if (
      packageInfo.subscriptionExpiryTimestamp &&
      packageInfo.subscriptionExpiryTimestamp < Date.now()
    ) {
      return false;
    }

    return packageInfo.purchasedQuantity >= packageInfo.usedQuantity;
  }

  async save() {
    const db = DBFactory.createDatabase();
    await db.save(this.toMap(), this.databaseConfig, false);
  }
}
