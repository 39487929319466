import { DBFactory } from "classes/database/db_factory";
import { BaseModel } from "../base.model";

type UserPackageVersionConstructorParams = {
  version: string;
  userId: string;
  organizationId?: string;
};

export type UserPackageVersions = UserPackageVersion[];

export class UserPackageVersion extends BaseModel {
  version: string;
  userId: string;
  organizationId?: string;

  constructor(params: UserPackageVersionConstructorParams) {
    super();

    this.version = params.version;
    this.userId = params.userId;
    this.organizationId = params.organizationId;
  }

  get databaseConfig(): ModelDatabaseConfig {
    return {
      collection: `/users/${this.userId}/capabilities`,
      path: `/users/${this.userId}/capabilities/${this.version}`,
    };
  }

  static fromMap(map: any): UserPackageVersion {
    return new UserPackageVersion(map);
  }

  toMap(): ModelDatabaseData {
    return {
      id: this.version,
      version: this.version,
      userId: this.userId,
      organizationId: this.organizationId,
    };
  }

  async save() {
    const db = DBFactory.createDatabase();
    await db.save(this.toMap(), this.databaseConfig);
  }
}
