import { DBFactory } from "classes/database/db_factory";
import { UserConsumables } from "./user-consumable.model";
import { Observable, map } from "rxjs";
import { CapabilityKey } from "~/types/enums/CapabilityKeys.enum";

export class ConsumablesService {
  static streamUserConsumables(userId: string): Observable<UserConsumables> {
    const db = DBFactory.createDatabase();
    return db
      .streamRecord({
        path: `/users/${userId}/consumables/${UserConsumables.id}`,
        collection: `/users/${userId}/consumables`,
      })
      .pipe(
        map((map) => {
          if (map == undefined) {
            return new UserConsumables({
              userId: userId,
              consumedCapabilities: {},
            });
          }

          return UserConsumables.fromMap(map);
        })
      );
  }

  static async getUserConsumables(userId: string): Promise<UserConsumables> {
    try {
      const db = DBFactory.createDatabase();
      const data = await db.get({
        path: `/users/${userId}/consumables/${UserConsumables.id}`,
        collection: `/users/${userId}/consumables`,
      });

      return UserConsumables.fromMap(data);
    } catch (error) {
      return new UserConsumables({
        userId: userId,
        consumedCapabilities: {},
      });
    }
  }

  // This function can be used to log a record of something like a document at a particular path
  static async addConsumedToKey(
    userId: string,
    capabilityKey: CapabilityKey,
    recordId: string
  ) {
    const db = DBFactory.createDatabase();
    await db.save(
      {
        id: recordId,
        userId: userId,
        timestamp: Date.now(),
      },
      {
        collection: `/users/${userId}/consumables/${UserConsumables.id}/${capabilityKey}`,
        path: `/users/${userId}/consumables/${UserConsumables.id}/${capabilityKey}/${recordId}`,
      }
    );
  }
}
