import { BaseModel } from "../base.model";
import { DBFactory } from "classes/database/db_factory";

export type Organizations = Organization[];

type OrganizationContrusctorParams = {
  id?: string;
  name: string;
  hasLMSConfig?: boolean;
};

export class Organization extends BaseModel {
  id?: string;
  name: string;
  hasLMSConfig: boolean;

  constructor(data: OrganizationContrusctorParams) {
    super(data);

    this.id = data.id;
    this.name = data.name;
    this.hasLMSConfig = data.hasLMSConfig || false;
  }

  get databaseConfig(): ModelDatabaseConfig {
    return {
      collection: "/organizations",
      path: `/organizations/${this.id}`,
    };
  }

  static fromMap(map: any) {
    return new Organization(map);
  }

  toMap(): ModelDatabaseData {
    return super.toMap();
  }

  async save() {
    const db = DBFactory.createDatabase();
    this.id = await db.save(this.toMap(), this.databaseConfig);
  }

  async delete() {
    const db = DBFactory.createDatabase();
    await db.delete(this.databaseConfig);
  }
}
