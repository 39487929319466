import {
  CapabilityPackage,
  CapabilityPackageConstructorParams,
} from "../capabilities/capability-package.model";
import { SubscriptionInfo } from "../capabilities/subscription-info.model";

export type OrganizationPackages = OrganizationPackage[];

interface OrganizationPackageConstructorParams
  extends CapabilityPackageConstructorParams {
  quantity: number; // Assuming this should be mandatory for organization packages
  organizationId: string;
  subscriptionInfo?: SubscriptionInfo;
}

export class OrganizationPackage extends CapabilityPackage {
  quantity: number;
  organizationId: string;
  packageId: string;
  subscriptionInfo?: SubscriptionInfo;

  constructor(params: OrganizationPackageConstructorParams) {
    super(params); // Call the parent constructor with the base parameters
    this.quantity = params.quantity;
    this.organizationId = params.organizationId;
    this.packageId = params.id ?? "not-set";
    this.subscriptionInfo = params.subscriptionInfo;
  }

  // Override the `databaseConfig` getter to change the storage path
  get databaseConfig() {
    return {
      path: `/organizations/${this.organizationId}/purchasedPackages/${this.id}`,
      collection: `/organizations/${this.organizationId}/purchasedPackages`,
    };
  }

  static fromMap(map: any): OrganizationPackage {
    return new OrganizationPackage(map);
  }

  static fromPackage({
    capabilityPackage,
    organizationId,
    quantity = 1,
    subscriptionInfo,
  }: {
    capabilityPackage: CapabilityPackage;
    organizationId: string;
    quantity: number;
    subscriptionInfo?: SubscriptionInfo;
  }): OrganizationPackage {
    return new OrganizationPackage({
      // Assuming `CapabilityPackageConstructorParams` includes all these fields
      id: capabilityPackage.id, // Be cautious with directly using `id` if it's supposed to be unique per instance
      name: capabilityPackage.name,
      category: capabilityPackage.category,
      version: capabilityPackage.version,
      lastUpdatedTimestamp: capabilityPackage.lastUpdatedTimestamp,
      capabilities: capabilityPackage.capabilities,
      allowQuantity: capabilityPackage.allowQuantity, // Make sure to include this if it's relevant
      // Additional properties for OrganizationPackage
      organizationId: organizationId,
      quantity: quantity,
      displayOrder: capabilityPackage.displayOrder,
      type: capabilityPackage.type,
      useCategoryAsId: capabilityPackage.useCategoryAsId,
      description: capabilityPackage.description,
      subscriptionInfo: subscriptionInfo,
    });
  }
}
