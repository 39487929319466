import { BaseModel } from "../base.model";

type UserCountsConstructorParams = {
  id: string;
  submissions: string[];
};

export class UserCounts extends BaseModel {
  id: string;
  submissions: string[];

  constructor(params: UserCountsConstructorParams) {
    super(params.id);
    this.id = params.id;
    this.submissions = params.submissions;
  }

  static fromMap(map: any): UserCounts {
    return new UserCounts(map);
  }
}
