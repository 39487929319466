import { CapabilityKey } from "~/types/enums/CapabilityKeys.enum";
import { Classroom, Classrooms } from "../classrooms/classroom.model";
import { ClassroomsService } from "../classrooms/classrooms.service";

import { useActiveClassrooms } from "~/composables/useActiveClassrooms";
import { useShouldCheckCapabilities } from "~/composables/useShouldCheckCapabilities";
import { CapabilityDataType } from "~/types/enums/CapabilityDataType.enum";
import { ConsumablesService } from "../consumables/consumables.service";
import { UserConsumables } from "../consumables/user-consumable.model";
import { OrganizationSummary } from "../organizations/organization-summary.model";
import { OrganizationsService } from "../organizations/organizations.service";
import { UserCounts } from "../userCounts/user-counts.model";
import { UserCountsService } from "../userCounts/user-counts.service";
import { ApplicationCapabilityDefinition } from "./application-capability-definition.model";
import { CapabilitiesService } from "./capabilities.service";
import { UserPackageVersion } from "./user-package-version.model";
import { UserPackage, UserPackages } from "./user-package.model";

import { PackageType } from "~/types/enums/PackageType.enum";

type UserCapabilitiesConstructorParams = {
  userId: string;
  applicationCapabilitiesDefinition: ApplicationCapabilityDefinition;
  userPackageVersion: UserPackageVersion;
  userPackages: UserPackages;
  userConsumables: UserConsumables;
  userCounts: UserCounts;
  userClassrooms: Classrooms;
  organizationSummary?: OrganizationSummary;
};

export class UserCapabilities {
  timestamp: number;
  userId: string;
  applicationCapabilitiesDefinition: ApplicationCapabilityDefinition;
  userPackageVersion: UserPackageVersion;
  userPackages: UserPackages;
  filteredUserPackages: UserPackages;
  userConsumables: UserConsumables;
  userCounts: UserCounts;
  userClassrooms: Classrooms;
  userCapabilityValues: { [key: string]: number | boolean | undefined };
  organizationSummary?: OrganizationSummary;

  constructor(data: UserCapabilitiesConstructorParams) {
    this.userId = data.userId;
    this.timestamp = Date.now();
    this.applicationCapabilitiesDefinition =
      data.applicationCapabilitiesDefinition;
    this.userPackageVersion = data.userPackageVersion;
    this.userPackages = data.userPackages;
    this.userConsumables = data.userConsumables;
    this.userCounts = data.userCounts;
    this.userClassrooms = data.userClassrooms;
    this.userCapabilityValues = {};

    this.organizationSummary = data.organizationSummary;
    this.filteredUserPackages = [...this.userPackages];

    if (this.organizationSummary != undefined) {
      this.filteredUserPackages = this.filteredUserPackages.filter((p) => {
        return p.organizationId == undefined || this.organizationSummary!.isPackageActive(p.packageId);
      });
    }
    this.populateCapabilityKeyValues();
  }

  static fromMap(map: any): UserCapabilities {
    return new UserCapabilities({
      userId: map.userId,
      applicationCapabilitiesDefinition:
        ApplicationCapabilityDefinition.fromMap(
          map.applicationCapabilitiesDefinition
        ),
      userPackageVersion: UserPackageVersion.fromMap(map.userPackageVersion),
      userPackages: map.userPackages.map((p: any) => UserPackage.fromMap(p)),
      userConsumables: UserConsumables.fromMap(map.userConsumables),
      userCounts: UserCounts.fromMap(map.userCounts),
      userClassrooms: map.userClassrooms.map((c: any) => Classroom.fromMap(c)),
    });
  }

  toMap(): any {
    return {
      userId: this.userId,
      timestamp: this.timestamp,
      applicationCapabilitiesDefinition:
        this.applicationCapabilitiesDefinition.toMap(),
      userPackageVersion: this.userPackageVersion.toMap(),
      userPackages: this.userPackages.map((p) => p.toMap()),
      userConsumables: this.userConsumables.toMap(),
      userCounts: this.userCounts.toMap(),
      userClassrooms: this.userClassrooms.map((c) => c.toMap()),
      userCapabilityValues: this.userCapabilityValues,
    };
  }

  populateCapabilityKeyValues() {
    for (const key of this.allUserCapabilityKeys()) {
      this.userCapabilityValues[key] = this.calculateCapabilityValue(key);
    }
  }

  static async initialize(userId: string): Promise<UserCapabilities> {

    
    const activeVersion = await CapabilitiesService.getUserCapabilitiesVersion(
      userId
    );

    const userPackageVersion = await CapabilitiesService.getUserPackageVersion(
      userId
    );

    let organizationSummary: OrganizationSummary | undefined = undefined;

    try {
      if (userPackageVersion.organizationId != undefined) {
        organizationSummary = await OrganizationsService.getOrganizationSummary(
          {
            organizationId: userPackageVersion.organizationId,
          }
        );
      }
    } catch (error) {
      // Do nothing
    }

    const applicationCapabilitiesDefinition =
      await CapabilitiesService.getApplicationCapabilityDefinition(
        activeVersion
      );

    // We exclude
    const userPackages = await CapabilitiesService.getActiveUserPackages(
      userId,
      activeVersion
    );


    const userConsumables = await ConsumablesService.getUserConsumables(userId);

    const userCounts = await UserCountsService.getUserCounts(userId);

    const userClassrooms = await ClassroomsService.listUserClassrooms(userId);

    const instance = new UserCapabilities({
      userId,
      applicationCapabilitiesDefinition,
      userPackageVersion: userPackageVersion,
      userPackages,
      userConsumables,
      userCounts,
      userClassrooms,
      organizationSummary,
    });

    return instance;
  }

  calculateCapabilityValue(key: CapabilityKey): number | boolean | undefined {
    const capabilityDefinition =
      this.applicationCapabilitiesDefinition.getCapabilityByKey(key);

    if (capabilityDefinition == undefined) return undefined;

    let value: number | boolean | undefined = undefined;

    switch (capabilityDefinition.dataType) {
      case CapabilityDataType.boolean:
        value = false;
        break;
      case CapabilityDataType.number:
        value = 0;
        break;
    }

    for (const userPackage of this.filteredUserPackages) {
      const packageValue = userPackage.getCapabilityValueWithDefinition(
        key,
        capabilityDefinition
      );

      if (packageValue == undefined) continue;

      if (capabilityDefinition.dataType == CapabilityDataType.boolean) {
        value = packageValue as boolean;
      }

      if (capabilityDefinition.dataType == CapabilityDataType.number) {
        (value as number) += packageValue as number;
      }
    }

    return value;
  }

  getCapabilityValue(key: CapabilityKey): number | boolean | undefined {
    return this.userCapabilityValues[key];
  }

  allUserCapabilityKeys(): CapabilityKey[] {
    // Loops through all the user packages and return a unique list of capability keys making sure to remove duplicates
    // userPackage.capabilities is a map where the key is the capabilityKey

    const capabilityKeys: CapabilityKey[] = [];

    for (const userPackage of this.filteredUserPackages) {
      for (const key in userPackage.capabilities) {
        if (capabilityKeys.includes(key as CapabilityKey)) continue;

        capabilityKeys.push(key as CapabilityKey);
      }
    }

    return capabilityKeys;
  }

  get activeClassrooms(): Classrooms {
    return useActiveClassrooms(this.userClassrooms);
  }

  public get numUsedSubmissions(): number {
    return this.userCounts.submissions.length;
  }

  public get numRemainingSubmissions(): number {
    return Math.max(0, this.submissionsLimit - this.numUsedSubmissions);
  }

  public get submissionsLimit(): number {
    return (
      (this.getCapabilityValue(CapabilityKey.submissionLimit) as number) ?? 0
    );
  }

  public get numExtraSubmissions(): number {
    return (
      (this.getCapabilityValue(CapabilityKey.extraSubmissions) as number) ?? 0
    );
  }

  public get numConsumedExtraSubmissions(): number {
    return this.userConsumables.getConsumedCapabilityValue(
      CapabilityKey.extraSubmissions
    );
  }

  public get numExtraSubmissionsRemaining(): number {
    return this.numExtraSubmissions - this.numConsumedExtraSubmissions;
  }

  public canGradeDocument(documentId: string): boolean {
    if (useShouldCheckCapabilities() != true) {
      return true;
    }

    if (this.hasSubscription != true) return false;

    if (this.userCounts == undefined) return false;

    if (this.userCounts.submissions.includes(documentId)) return true;

    if (this.getCapabilityValue(CapabilityKey.unlimitedSubmissions)) {
      return true;
    }

    if (this.numUsedSubmissions < this.submissionsLimit) return true;

    if (this.numExtraSubmissionsRemaining > 0) return true;

    return false;
  }

  public get numAllowedClassrooms(): number {
    return (
      (this.getCapabilityValue(CapabilityKey.classroomLimit) as number) ?? 0
    );
  }

  public get numActiveClassrooms(): number {
    return this.activeClassrooms.length;
  }

  public get hasEnoughClassroomLicenses(): boolean {
    if (useShouldCheckCapabilities() != true) {
      return true;
    }

    if (this.getCapabilityValue(CapabilityKey.unlimitedClassrooms) == true)
      return true;

    return this.numActiveClassrooms <= this.numAllowedClassrooms;
  }

  public get canUserAddClassroom(): boolean {
    if (useShouldCheckCapabilities() != true) {
      return true;
    }

    if (this.getCapabilityValue(CapabilityKey.unlimitedClassrooms) == true)
      return true;

    return this.numActiveClassrooms < this.numAllowedClassrooms;
  }

  public canRemovePackage(packageId: string): boolean {
    const userPackage = this.userPackages.find((p) => p.packageId == packageId);

    if (userPackage == undefined) return false;

    if (userPackage.quantity == 0) return false;

    if (userPackage.allowQuantity == false) return true;

    if (userPackage.type != PackageType.consumable) return true;

    let canRemovePackage = true;

    for (const capabilityKey of Object.keys(userPackage.capabilities)) {
      const value = userPackage.getCapabilityValue(capabilityKey);
      const totalAvailable = this.getCapabilityValue(
        capabilityKey as CapabilityKey
      );

      if (value == undefined) continue;
      if (typeof value != "number") continue;

      if (totalAvailable == undefined) continue;
      if (typeof totalAvailable != "number") continue;

      const consumedQuantity = this.userConsumables.getConsumedCapabilityValue(
        capabilityKey as CapabilityKey
      );

      const totalRemaining = totalAvailable - consumedQuantity;

      if (value > totalRemaining) {
        canRemovePackage = false;
        break;
      }
    }

    return canRemovePackage;
  }

  public get hasSubscription(): boolean {
    // Has at least one package with a primary plan

    return this.filteredUserPackages.some((p) => p.category == "primary-plan");
  }
}
