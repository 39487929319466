import { BaseModel } from "../base.model";
import { DBFactory } from "~/classes/database/db_factory";

type SubscriptionRenewalPeriodConstructorParams = {
  id: string;
  subscriptionRenewalDayOfMonth: number;
};

export class SubscriptionRenewalPeriod extends BaseModel {
  id: string;
  subscriptionRenewalDayOfMonth: number;

  constructor(params: SubscriptionRenewalPeriodConstructorParams) {
    super(params.id);
    this.id = params.id;

    this.subscriptionRenewalDayOfMonth = params.subscriptionRenewalDayOfMonth;
  }

  get databaseConfig(): ModelDatabaseConfig {
    return {
      collection: "/subscriptionRenewalPeriods",
      path: `/subscriptionRenewalPeriods/${this.id}`,
    };
  }

  static fromMap(map: any): SubscriptionRenewalPeriod {
    return new SubscriptionRenewalPeriod(map);
  }

  toMap(): ModelDatabaseData {
    return {
      id: this.id,
      subscriptionRenewalDayOfMonth: this.subscriptionRenewalDayOfMonth,
    };
  }

  async save() {
    const db = DBFactory.createDatabase();
    this.id = await db.save(this.toMap(), this.databaseConfig);
  }

  async delete() {
    const db = DBFactory.createDatabase();
    await db.delete(this.databaseConfig);
  }
}
