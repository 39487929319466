import { useCurrentYearMonth } from "~/composables/useCurrentYearMonth";

export const useCreateUserCountKey = ({
  subscriptionRenewalDayOfMonth,
}: {
  subscriptionRenewalDayOfMonth?: number;
}) => {
  if (subscriptionRenewalDayOfMonth == undefined) {
    return useCurrentYearMonth();
  }

  const currentDate = new Date();
  const currentDayOfMonth = currentDate.getDate();

  if (currentDayOfMonth < subscriptionRenewalDayOfMonth) {
    currentDate.setMonth(currentDate.getMonth() - 1);
  }

  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1;
  return `${year}-${month
    .toString()
    .padStart(2, "0")}-${subscriptionRenewalDayOfMonth
    .toString()
    .padStart(2, "0")}`;
};
